/********************************************
* IMPORS FOR ANY ASSETS IN THE TEMPLATE
********************************************/
@import "ext/avisos/avisos";
@import "ext/neblina/neblina";
@import "ext/bootstrap/bootstrap";
@import "ext/fontawesome/font-awesome";
@import "ext/mmenu/mmenu";
@import "ext/mmenu/mmenu";

.discount-bullet { 
  background-color: #ef643a;
  white-space: nowrap;
  padding: 3px 6px;
  top: 0px;
  position: absolute;
  width: 58px !important;
  height: 23px !important;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  right: 0;
  margin-right: 3%;

  @media (max-width: 450px) {
    padding: 5px 6px;
    width: 46px !important;
    height: 22px !important;
    font-size: 9px;
  }
}
.discount-bullet::before{
  content: '';
  position: absolute;
  top: 99%;
  left: 0px;
  right: 0px;
  border-top: 20px solid #ef643a;
  border-left: 29px solid transparent;
  border-right: 29px solid transparent;  

  @media (max-width: 450px) {
    border-top: 13px solid #ef643a;
    border-left: 23px solid transparent;
    border-right: 23px solid transparent;
  }
}

.discount-bullet-frete-gratis {
  width: 74px;
  height: auto !important;
  background: #ef643a;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 6px;
  font-weight: 400;
  left: 0px;
  bottom: 0;
  text-align: center;
  position: absolute;

}

.zoomWrapper.video img{
  max-width: 100%;
}

@media (max-width: 991px) {
  .frete-gratis {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
}