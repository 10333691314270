/* Folha de Estilo para avisos */

.av [class^="icon-"], .av [class*=" icon-"]{
  background-image: none;
  height: auto;
  width: auto;
}
.av{
  background-color: #fff;
  background-position: 100% 12px;
  background-repeat: no-repeat;
  border-radius: 8px;
  color: #fff;
  overflow: hidden;
  padding: 56px 40px 40px;
  position: fixed;
  top: 56px;
  width: 480px;
  z-index: 9998;
}

.av .av-titulo{
  color: #ffffff;
  font: 200 48px Helvetica, Arial, sans-serif;
  margin: 0 0 4px 0;
  position: relative;
  text-transform: none;
  z-index: 1;
}

.av .av-texto{
  color: #ffffff;
  font: 200 18px Helvetica, Arial, sans-serif;
  margin: 0 0 4px 0;
  position: relative;
  text-transform: none;
  z-index: 1;
}

.av .av-bg-icone{
  color: #fff;
  font-size: 140px;
  opacity: 0.2;
  position: absolute;
  right: -10px;
  top: 30px;
  z-index: 0;
}

.av a{
  color: #fff;
  text-decoration: underline;
}

.av a:hover{
  text-decoration: none;
}

.av .topico .av-titulo{
  color: #4e5f6f;
  font: 700 13px Helvetica, Arial, sans-serif;
}

.av .topico .av-texto{
  color: #4e5f6f !important;
  font: 400 13px Helvetica, Arial, sans-serif;
  margin: 0;
}

.av .av-fechar{
  cursor: pointer;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  right: 12px;
  text-align: center;
  top: 12px;
  width: 26px;
  z-index: 2;
}

.av .av-timer{
  border: 2px solid #fff;
  border-radius: 25px;
  font-size: 12px;
  height: 26px;
  line-height: 22px;
  opacity: 0.5;
  text-align: center;
  width: 26px;
}

.av .av-fechar .fa{
  border:1px solid #fff;
  border-radius: 25px;
  left:18px;
  opacity: 0.7;
  position: absolute;
  top:-10px;
  width: 14px;
}

.av .av-timer em {
  font-size: 9px;
  margin-left: 1px;
}

.av.aguarde .av-conteudo{
  float: left;
  margin-left: 10px;
  position: relative;
  width: 350px;
  z-index: 1;
}

.av.aguarde .av-loading{
  float: left;
  font-size: 40px;
  opacity: 0.8;
  position: relative;
  z-index: 1;
}

.av .topico{
  border-top: 1px solid #e0e0e0;
  float: left;
  padding: 10px 20px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.av .topico:first-of-type{
  border-top: none;
}

.av.av-multiplo{
  background-color: #fff;
  background-image: none;
  color: #666;
  padding: 7px 0 0;
  position: absolute;
}

.av.av-multiplo .av-fechar{
  color: #c0c0c0;
}

.av.av-multiplo .av-conteudo{
  float: left;
  width: 85%;
}

.av.av-multiplo .av-icone{
  float: left;
  font-size: 28px;
  margin-right: 10px;
  width: 28px;
}

.av-alerta{
  background-color: #eb9b37;
  background-image: url("../images/avisos/aviso-alerta.png");
}

.topico-alerta .av-icone{
  color: #eb9b37;
}

.av-sucesso{
  background-color: #ff5b00 !important;
  background-image: url("../images/avisos/aviso-sucesso.png");
}

.topico-sucesso .av-icone{
  color: #378dcc;
}

.av-erro{
  background-color: #d75452;
  background-image: url("../images/avisos/aviso-erro.png");
}

.topico-erro .av-icone{
  color: #d75452;
}

.av-info{
  background-color: #66d99f;
  background-image: url("../images/avisos/aviso-info.png");
}

.topico-info .av-icone{
  color: #66d99f;
}

.av.av-modelo-1{
  background-color: #fff;
  color: #4e5f6f;
  padding: 38px 40px 48px;
  text-align: center;
  width: 410px;
}

.av.av-modelo-1 .av-icone{
  color: #66d99f;
  font-size: 48px;
  position: relative;
  right: auto;
  top: auto;
}

.av.av-modelo-1 .av-titulo{
  color: #4e5f6f ;
  font-weight: 200;
  padding-top: 10px;
}
.av.av-modelo-1 .av-texto {
  padding-top: 10px;
}

.av.av-modelo-1 .av-titulo,
.av.av-modelo-1 .av-conteudo{
  width: 100%;
}

.av.av-modelo-1 .av-fechar{
  background-color: #66d99f;
  color: #fff;
  cursor: pointer;
  height: 30px;
  line-height: 20px;
  position: relative;
  right: auto;
  text-transform: none;
  top: auto;
  width: 90%;
}


@media (max-width: 768px){
  .av {
    padding: 36px 20px 20px;
    width: 290px;
  }

  .av .av-titulo{
    font-size: 36px;
  }
  .av.aguarde .av-conteudo{
    width: 205px;
  }
}
